e4.dealersearch = {
    GoogleMapsAPIkey: '',
    countrycode: '',
    data: {},
    jMap: [],
    gmMap: {},
    gmGeocoder: function () {},
    init: function () {
        var jDealersearch = jQuery( '.js-e-dealersearch' );
        if ( jDealersearch.length === 1 ) {
            e4.dealersearch.GoogleMapsAPIkey = jDealersearch.data( 'googlemapsapikey' );
            e4.dealersearch.jMap = jDealersearch.find( '.js-e-dealersearch-googlemap' );
            e4.dealersearch.countrycode = jDealersearch.data( 'countrycode' );
            e4.dealersearch.getData();
            e4.dealersearch.apis.load();
        }
    },
    apis: {
        load: function () {
            jQuery.when.apply( jQuery, [
                e4.dealersearch.apis.GoogleMaps.load(),
                e4.dealersearch.apis.MarkerClusterer.load(),
                e4.dealersearch.apis.jQueryUImap.load(),
                e4.dealersearch.apis.typeahead.load(),
                e4.dealersearch.apis.handlebars.load()
            ] ).then( function () {
                e4.dealersearch.map.init();
            } );
        },
        GoogleMaps: {
            timeout: null,
            deferred: jQuery.Deferred(),
            load: function () {
                if ( window.google !== undefined && google.maps !== undefined ) {
                    return e4.dealersearch.apis.GoogleMaps.callback();
                }
                else {
                    var gm     = document.createElement( 'script' ),
                        script = document.getElementsByTagName( 'script' )[ 0 ];
                    gm.src = '//maps.googleapis.com/maps/api/js?key=' + e4.dealersearch.GoogleMapsAPIkey + '&callback=' + 'e4.dealersearch.apis.GoogleMaps.callback';
                    script.parentNode.insertBefore( gm, script );
                    e4.dealersearch.apis.GoogleMaps.timeout = setTimeout( e4.dealersearch.apis.GoogleMaps.deferred.reject, e4.settings.require.timeout );
                    return e4.dealersearch.apis.GoogleMaps.deferred.promise();
                }
            },
            callback: function () {
                clearTimeout( e4.dealersearch.apis.GoogleMaps.timeout );
                e4.dealersearch.apis.GoogleMaps.timeout = null;
                e4.dealersearch.gmGeocoder = new google.maps.Geocoder();
                return e4.dealersearch.apis.GoogleMaps.deferred.resolve();
            }
        },
        jQueryUImap: {
            load: function () {
                return e4.util.require.js( e4.settings.require.jQueryUImap );
            }
        },
        MarkerClusterer: {
            load: function () {
                return e4.util.require.js( e4.settings.require.MarkerClusterer );
            }
        },
        handlebars: {
            load: function () {
                return e4.util.require.js( e4.settings.require.handlebars, function () {
                    e4.handlebars.registerHelpers( 'loop' ); //TODO: Refactor using built-in helper each
                    e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-typeahead-footer #js-e-handlebars-tmpl-typeahead-pending #js-e-handlebars-tmpl-googlemap-infowindow #js-e-handlebars-tmpl-marker-list' );
                } );
            }
        },
        typeahead: {
            load: function () {
                return e4.util.require.js( e4.settings.require.typeahead, function () {
                    e4.dealersearch.form.init();
                } );
            }
        }
    },
    search: function ( address ) {
        e4.dealersearch.map.setVirginState( false );
        e4.dealersearch.markers.updateDistanceToAddress( address );
        e4.dealersearch.jMap.gmap( 'get', 'map' ).fitBounds( e4.dealersearch.map.getBoundsByAddress( address ) );
        e4.dealersearch.map.fitResults();
        e4.dealersearch.list.syncWithMap();
        e4.dealersearch.form.hidePlaceholder();
        jQuery( '.js-e-dealersearch-input' ).typeahead( 'val', address.formatted_address );
        // e4.util.hash.replace( 'dealersearch', address.formatted_address );
        // TODO: Move to gtm namespace
        e4.gtm.pushDataLayer( {
            'eventCategory': 'Forhandlersøgning',
            'eventAction': address.formatted_address,
            'eventLabel': location.pathname,
            'eventValue': 0,
            'eventInteraction': false,
            'event': 'track-ga-event'
        } );
    },
    getData: function () {
        var intDealersearchID = jQuery( '.js-e-dealersearch' ).data( 'id' );
        e4.dealersearch.data = e4.data.dealersearch[ intDealersearchID ].users;
        e4.data.dealersearch[ intDealersearchID ].users = null;
    },
    setCountry: function ( countrycode ) {
        e4.dealersearch.countrycode = countrycode;
        e4.dealersearch.map.setCenterAndBoundsFromCountry();
        e4.dealersearch.form.clearInput();
        e4.dealersearch.clusterer.updateMarkers();
    },
    geoLocation: {
        init: function () {
            // if ( 'geolocation' in navigator === true ) {
            //     e4.dealersearch.geoLocation.bindEvents();
            // }
        },
        bindEvents: function () {
        },
        search: function () {
            navigator.geolocation.getCurrentPosition( function ( position ) {
                e4.dealersearch.gmGeocoder.geocode( {
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }, function ( addresses, status ) {
                    if ( status === 'OK' ) {
                        jQuery.each( addresses[ 0 ].address_components, function ( index, address_component ) {
                            jQuery.each( address_component.types, function ( index, address_type ) {
                                if ( address_type === 'country' ) {
                                    //TODO: Lav check for om landet findes i listen.
                                    jQuery( '.js-e-dealersearch-country' ).val( address_component.short_name ).triggerHandler( 'change' );
                                }
                            } );
                        } );
                        e4.dealersearch.search( addresses[ 0 ] );
                        jQuery( '.js-e-dealersearch-input' ).typeahead( 'close' );
                    }
                    else {
                        e4.dealersearch.map.logError( status );
                    }
                } );
            }, function ( error ) {
                // TODO: Move to gtm namespace
                e4.gtm.pushDataLayer( {
                    'eventCategory': 'navigator.geolocation.getCurrentPosition',
                    'eventAction': error.code + ': ' + error.message,
                    'eventLabel': location.pathname,
                    'eventValue': 0,
                    'eventInteraction': false,
                    'event': 'track-ga-event'
                } );
            } );
        }
    },
    form: {
        init: function () {
            e4.dealersearch.form.bindEvents();
            // var strQuery = e4.util.hash.get( 'dealersearch' );
            // if ( strQuery !== undefined ) {
            //     jQuery( '.js-e-dealersearch-input' ).typeahead( 'val', strQuery ).triggerHandler( 'input' );
            //     jQuery( '.js-e-dealersearch-form' ).triggerHandler( 'submit' );
            // }
        },
        bindEvents: function () {
            jQuery( '.js-e-dealersearch-form' ).on( 'submit', function ( event ) {
                event.preventDefault();
                var jSuggestionFirst = jQuery( this ).find( '.js-e-dealersearch-suggestions' ).find( '.tt-selectable' ).first();
                if ( jSuggestionFirst.length === 1 ) {
                    jSuggestionFirst.trigger( 'click' );
                }
                else if ( jSuggestionFirst.length === 0 ) {
                    e4.dealersearch.geoLocation.search();
                }
            } ).on( 'click', '.js-e-dealersearch-submit', function () {
                jQuery( this.form ).triggerHandler( 'submit' );
            } ).on( 'click', '.js-e-dealersearch-geolocate', function ( event ) {
                event.preventDefault();
                e4.dealersearch.geoLocation.search();
            } );
            jQuery( '.js-e-dealersearch-country' ).on( 'change', function () {
                e4.dealersearch.setCountry( this.value );
            } );
            jQuery( '.js-e-dealersearch-input' ).on( 'keydown', function ( event ) {
                if ( event.keyCode === 13 ) {
                    var jSuggestions      = jQuery( this.form ).find( '.js-e-dealersearch-suggestions' ),
                        jSuggestionActive = jSuggestions.find( '.tt-selectable' ).filter( '.active' );
                    if ( jSuggestionActive.length === 0 && jSuggestions.css( 'display' ) === 'block' ) {
                        jQuery( this.form ).triggerHandler( 'submit' );
                    }
                    else if ( jSuggestionActive.is( '.js-e-dealersearch-geolocate' ) === true ) {
                        jSuggestionActive.trigger( 'click' );
                    }
                }
            } ).on( 'focus input', function () {
                e4.dealersearch.form.hidePlaceholder();
            } ).on( 'blur', function () {
                if ( this.value === '' ) {
                    e4.dealersearch.form.showPlaceholder();
                }
            } );
            jQuery( '.js-e-dealersearch-input' ).typeahead( {
                hint: true,
                highlight: true, // autoselect: true,
                minLength: 0,
                classNames: {
                    wrapper: 'e-dealersearch-typeahead-wrapper js-e-dealersearch-typeahead-wrapper w-100',
                    input: 'js-e-dealersearch-input', //hint: 'js-e-dealersearch-input-hint',
                    // //https://github.com/twitter/typeahead.js/issues/677#issuecomment-48807067
                    menu: 'js-e-dealersearch-suggestions e-dealersearch-suggestions dropdown-menu', //dataset:
                    // 'js-e-dealersearch-dataset',
                    suggestion: 'js-e-dealersearch-suggestions-item dropdown-item', //empty: ,
                    open: 'open',
                    cursor: 'active',
                    highlight: 'active'
                }
            }, {
                name: 'espresso',
                display: 'formatted_address',
                limit: 8,
                async: true,
                source: function ( query, syncResults, asyncResults ) {
                    e4.dealersearch.form.getSuggestions( query, function ( addresses ) {
                        asyncResults( addresses );
                    } );
                },
                templates: {
                    pending: function ( data ) {
                        //console.log( 'pending', data );
                        return e4.handlebars.tmpl.typeaheadPending( data );
                    }, // header: function ( data ) {
                    //     //console.log( 'header', data );
                    // },
                    footer: function ( data ) {
                        //console.log( 'footer', data );
                        return e4.handlebars.tmpl.typeaheadFooter( data );
                    }
                }
            } ).on( 'typeahead:autocomplete typeahead:select', function ( event, address ) { // typeahead:cursorchange
                if ( address !== null ) {
                    e4.dealersearch.search( address );
                    jQuery( this ).typeahead( 'close' );
                }
            } );
        },
        getSuggestions: function ( query, asyncResults ) {
            e4.dealersearch.gmGeocoder.geocode( {
                address: query,
                componentRestrictions: {
                    country: e4.dealersearch.countrycode //https://en.wikipedia.org/wiki/ISO_3166-1#Current_codes
                }
            }, function ( addresses, status ) {
                if ( status === 'OK' ) {
                    addresses = addresses.filter( function ( address ) {
                        return address.address_components[ 0 ].types[ 0 ] !== 'country';
                    } );
                    asyncResults( addresses );
                }
                else {
                    e4.dealersearch.map.logError( status );
                }
            } );
        },
        clearInput: function () {
            jQuery( '.js-e-dealersearch-input' ).typeahead( 'val', '' );
        },
        showPlaceholder: function () {
            jQuery( '.js-e-dealersearch-input-placeholder' ).removeClass( 'is-hidden' );
        },
        hidePlaceholder: function () {
            jQuery( '.js-e-dealersearch-input-placeholder' ).addClass( 'is-hidden' );
        }
    },
    map: {
        hasVirginState: true,
        init: function () {
            e4.dealersearch.jMap.gmap( {
                center: e4.dealersearch.map.getCenterByCountry(),
                zoom: e4.dealersearch.map.getZoomByCountry(),
                mapTypeControl: false,
                scrollwheel: false
            } ).on( 'init', function () {
                // console.log( 'map init' );
                e4.dealersearch.map.setCenterAndBoundsFromCountry();
                e4.dealersearch.clusterer.init();
                e4.dealersearch.markers.init();
                e4.dealersearch.list.init();
                e4.dealersearch.map.bindEvents();
            } );
        },
        bindEvents: function () {
            google.maps.event.addListener( e4.dealersearch.jMap.gmap( 'get', 'map' ), 'idle', function () {
                if ( e4.dealersearch.map.getVirginState() === true ) {
                    e4.dealersearch.list.clear();
                }
                else {
                    if ( e4.util.getBreakPoint() !== 'xs' && e4.util.getBreakPoint() !== 'sm' ) {
                        e4.dealersearch.list.syncWithMap();
                        // console.log( 'sync list from map idle' );
                    }
                }
                e4.dealersearch.map.setVirginState( false );
            } );
            jQuery( window ).on( 'resize', function () {
                e4.dealersearch.jMap.gmap( 'refresh' );
            } );
        },
        setVirginState: function ( boolVirginState ) {
            e4.dealersearch.map.hasVirginState = boolVirginState;
        },
        getVirginState: function () {
            return e4.dealersearch.map.hasVirginState;
        },
        setCenterAndBoundsFromCountry: function () {
            e4.dealersearch.map.setVirginState( true );
            // If custom settings for center and zoom are present.
            if ( e4.settings.dealersearch.country[ e4.dealersearch.countrycode ] !== undefined ) {
                var gmMap = e4.dealersearch.jMap.gmap( 'get', 'map' );
                gmMap.setCenter( e4.dealersearch.map.getCenterByCountry() );
                gmMap.setZoom( e4.dealersearch.map.getZoomByCountry() );
            }
            // If no center and zoom settings are present, use geocode to get values from current country.
            else {
                e4.dealersearch.gmGeocoder.geocode( {
                    address: e4.dealersearch.countrycode,
                    componentRestrictions: {
                        country: e4.dealersearch.countrycode //https://en.wikipedia.org/wiki/ISO_3166-1#Current_codes
                    }
                }, function ( addresses, status ) {
                    if ( status === 'OK' ) {
                        var mapLatLngBounds = e4.dealersearch.map.getBoundsByAddress( addresses[ 0 ] );
                        e4.dealersearch.jMap.gmap( 'get', 'map' ).fitBounds( mapLatLngBounds );
                    }
                    else {
                        e4.dealersearch.map.logError( status );
                    }
                } );
            }
        },
        getCenterByCountry: function () {
            if ( e4.settings.dealersearch.country[ e4.dealersearch.countrycode ] !== undefined ) {
                return new google.maps.LatLng( e4.settings.dealersearch.country[ e4.dealersearch.countrycode ].center );
            }
            else {
                return new google.maps.LatLng( {
                    lat: 0,
                    lng: 0
                } );
            }
        },
        getZoomByCountry: function () {
            if ( e4.settings.dealersearch.country[ e4.dealersearch.countrycode ] !== undefined ) {
                return e4.settings.dealersearch.country[ e4.dealersearch.countrycode ].zoom;
            }
            else {
                return 1;
                //https://developers.google.com/maps/documentation/static-maps/intro#Zoomlevels
                // 1: World
                // 5: Landmass/continent
                // 10: City
                // 15: Streets
                // 20: Buildings
            }
        },
        getBoundsByAddress: function ( address ) {
            var mapLatLngBounds;
            // console.log( address );
            if ( address.geometry.viewport !== undefined ) {
                mapLatLngBounds = address.geometry.viewport;
            }
            else if ( address.geometry.bounds !== undefined ) {
                mapLatLngBounds = address.geometry.bounds;
            }
            else {
                mapLatLngBounds = new google.maps.LatLngBounds();
                mapLatLngBounds.extend( address.geometry.location );
            }
            return mapLatLngBounds;
        },
        getMarkersInBounds: function () {
            var mapLatLngBounds    = e4.dealersearch.jMap.gmap( 'get', 'map' ).getBounds(),
                arrMarkersInBounds = [];
            jQuery.each( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ], function ( index, marker ) {
                if ( mapLatLngBounds.contains( marker.getPosition() ) === true ) {
                    arrMarkersInBounds.push( marker );
                }
            } );
            return arrMarkersInBounds;
        },
        extendBoundsToMarker: function ( marker ) {
            var mapLatLngBounds = e4.dealersearch.jMap.gmap( 'get', 'map' ).getBounds();
            mapLatLngBounds.extend( marker.getPosition() );
            e4.dealersearch.jMap.gmap( 'get', 'map' ).fitBounds( mapLatLngBounds );
        },
        fitResults: function () {
            var intMapZoom = e4.dealersearch.jMap.gmap( 'get', 'map' ).getZoom();
            // Make sure that map zoom is within min/max settings.
            if ( intMapZoom > e4.settings.dealersearch.results.zoom.max ) {
                // console.log( 'max', intMapZoom, e4.settings.dealersearch.results.zoom.max )
                e4.dealersearch.jMap.gmap( 'get', 'map' ).setZoom( e4.settings.dealersearch.results.zoom.max );
            }
            else if ( intMapZoom < e4.settings.dealersearch.results.zoom.min ) {
                // console.log( 'min', intMapZoom, e4.settings.dealersearch.results.zoom.min )
                e4.dealersearch.jMap.gmap( 'get', 'map' ).setZoom( intMapZoom + 1 );
            }
            // If no markers are in bounds, then extend bounds to closest marker.
            if ( e4.dealersearch.map.getMarkersInBounds().length === 0 ) {
                e4.dealersearch.map.extendBoundsToMarker( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ][ 0 ] );
            }
            // While markers in bounds is below min result setting, extend bounds to closest marker.
            for ( var m = e4.dealersearch.map.getMarkersInBounds().length; m <= e4.settings.dealersearch.results.min; m++ ) {
                // Break if there is no more markers to show.
                if ( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ].length > m ) {
                    // If marker is within calculated sensitivity distance to previous marker, extend bounds to marker.
                    if ( m > 0 && e4.dealersearch.data.markers[ e4.dealersearch.countrycode ][ m ].distanceToAddress < e4.dealersearch.data.markers[ e4.dealersearch.countrycode ][ m - 1 ].distanceToAddress * ( 1 + Math.pow( e4.settings.dealersearch.results.sensitivity, m ) ) ) {
                        e4.dealersearch.map.extendBoundsToMarker( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ][ m ] );
                        // console.log('added',e4.dealersearch.data.markers[e4.dealersearch.countrycode][ m
                        // ].data.name);
                    }
                    else {
                        break;
                    }
                }
                else {
                    break;
                }
            }
        },
        logError: function ( strError ) {
            // TODO: Move to gtm namespace
            e4.gtm.pushDataLayer( {
                'eventCategory': 'Google Maps Error',
                'eventAction': strError,
                'eventLabel': location.pathname,
                'eventValue': 0,
                'eventInteraction': false,
                'event': 'track-ga-event'
            } );
        }
    },
    clusterer: {
        init: function () {
            e4.dealersearch.jMap.gmap( 'set', 'MarkerClusterer', new MarkerClusterer( e4.dealersearch.jMap.gmap( 'get', 'map' ), [], {
                maxZoom: e4.settings.dealersearch.results.zoom.max - 1,
                //minimumClusterSize: 5,
                averageCenter: true,
                //gridSize: 40,
                //styles: styles[ style ],
                imagePath: '//cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m'
            } ) );
        },
        addMarkers: function () {
            e4.dealersearch.jMap.gmap( 'get', 'MarkerClusterer' ).addMarkers( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ] );
        },
        updateMarkers: function () {
            if ( e4.dealersearch.jMap.gmap( 'get', 'MarkerClusterer' ).getTotalMarkers() > 0 ) {
                e4.dealersearch.clusterer.clearMarkers();
            }
            e4.dealersearch.clusterer.addMarkers();
        },
        clearMarkers: function () {
            e4.dealersearch.jMap.gmap( 'get', 'MarkerClusterer' ).clearMarkers();
        }
    },
    markers: {
        init: function () {
            e4.dealersearch.markers.build();
            e4.dealersearch.clusterer.addMarkers();
            e4.dealersearch.markers.bindEvents();
        },
        build: function () {
            e4.dealersearch.data.markers = {};
            jQuery.each( e4.dealersearch.data, function ( index, user ) {
                var lat = parseFloat( user.lat ),
                    lng = parseFloat( user.lng );
                if ( isNaN( lat ) || isNaN( lng ) || Math.abs( lat ) > 90 || Math.abs( lng ) > 180 ) {
                    console.error( 'Error - lat/lng data not valid for this address: ' + user.name + '' );
                }
                else if ( user.countrycode !== '' ) {
                    if ( Array.isArray( e4.dealersearch.data.markers[ user.countrycode ] ) === false ) {
                        e4.dealersearch.data.markers[ user.countrycode ] = [];
                    }
                    e4.dealersearch.data.markers[ user.countrycode ].push( new google.maps.Marker( {
                        id: user.id,
                        position: new google.maps.LatLng( lat, lng ),
                        data: user,
                        distanceToAddress: 0
                    } ) );
                }
            } );
        },
        bindEvents: function () {
            jQuery.each( e4.dealersearch.data.markers, function ( country, arrCountryMarkers ) {
                jQuery.each( arrCountryMarkers, function ( index, marker ) {
                    google.maps.event.addListener( marker, 'click', function () {
                        e4.dealersearch.jMap.gmap( 'openInfoWindow', {
                            'content': e4.dealersearch.markers.getInfoWindowContent( marker )
                        }, marker );
                        // TODO: Move to gtm namespace
                        e4.gtm.pushDataLayer( {
                            'eventCategory': 'Forhandlerkort',
                            'eventAction': marker.data.name,
                            'eventLabel': location.pathname,
                            'eventValue': 0,
                            'eventInteraction': false,
                            'event': 'track-ga-event'
                        } );
                    } );
                } );
            } );
        },
        get: function ( id ) {
            for ( var m = 0; m < e4.dealersearch.data.markers[ e4.dealersearch.countrycode ].length; m++ ) {
                if ( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ][ m ].id === id ) {
                    return e4.dealersearch.data.markers[ e4.dealersearch.countrycode ][ m ];
                }
            }
            return null;
        },
        getInfoWindowContent: function ( marker ) {
            return e4.handlebars.tmpl.googlemapInfowindow( marker.data );
        },
        showOnMap: function ( marker ) {
            //e4.dealersearch.markers.clearDistanceToAddress();
            var gmMap = e4.dealersearch.jMap.gmap( 'get', 'map' );
            gmMap.panTo( marker.getPosition() );
            gmMap.setZoom( e4.settings.dealersearch.results.zoom.max - 1 );
            google.maps.event.trigger( marker, 'click' );
        },
        updateDistanceToAddress: function ( address ) {
            var lat = address.geometry.location.lat(),
                lng = address.geometry.location.lng();
            jQuery.each( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ], function ( index, marker ) {
                marker.distanceToAddress = e4.dealersearch.markers.math.getGreatCircleDistance( lat, lng, marker.getPosition().lat(), marker.getPosition().lng() );
            } );
            e4.dealersearch.markers.sortByDistanceToAddress();
        },
        clearDistanceToAddress: function () {
            jQuery.each( e4.dealersearch.data.markers[ e4.dealersearch.countrycode ], function ( index, marker ) {
                marker.distanceToAddress = 0;
            } );
        },
        sortByDistanceToAddress: function () {
            e4.dealersearch.data.markers[ e4.dealersearch.countrycode ].sort( function ( a, b ) {
                return a.distanceToAddress - b.distanceToAddress;
            } );
        },
        math: {
            formatDistance: function ( number ) {
                if ( e4.dealersearch.unitsystem === 'imperial' ) {
                    number = number / 1.609344;
                }
                return Math.round( number * 100 ) / 100;
                // Number().toLocaleString( e4.settings.lang, {
                //     minimumFractionDigits: 2
                // } );
            },
            toRadians: function ( number ) {
                return number * ( Math.PI / 180 );
            },
            getGreatCircleDistance: function ( lat1, lng1, lat2, lng2 ) {
                // https://en.wikipedia.org/wiki/Haversine_formula
                var dLat = e4.dealersearch.markers.math.toRadians( lat2 - lat1 ),
                    dLng = e4.dealersearch.markers.math.toRadians( lng2 - lng1 ),
                    a    = Math.sin( dLat / 2 ) * Math.sin( dLat / 2 ) + Math.sin( dLng / 2 ) * Math.sin( dLng / 2 ) * Math.cos( e4.dealersearch.markers.math.toRadians( lat1 ) ) * Math.cos( e4.dealersearch.markers.math.toRadians( lat2 ) );
                return 6371 * ( 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) ) );
            }
        }
    },
    list: {
        init: function () {
            e4.dealersearch.list.bindEvents();
        },
        bindEvents: function () {
            jQuery( '.js-e-dealersearch-list' ).on( 'click', '.js-e-dealersearch-list-item', function () {
                e4.dealersearch.markers.showOnMap( e4.dealersearch.markers.get( jQuery( this ).data( 'id' ) ) );
            } ).on( 'click', '.js-e-dealersearch-show-map', function ( event ) {
                event.preventDefault();
                jQuery( '.js-e-dealersearch-googlemap-container' ).addClass( 'is-active' );
                e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-dealersearch-googlemap-container' ) );
            } );
        },
        syncWithMap: function () {
            var arrMarkersInBounds = e4.dealersearch.map.getMarkersInBounds(),
                json               = {
                    markers: []
                };
            if ( arrMarkersInBounds.length === 0 ) {
                e4.dealersearch.list.clear();
            }
            else {
                jQuery.each( arrMarkersInBounds, function ( index, marker ) {
                    json.markers.push( jQuery.extend( marker.data, {
                        distanceToAddress: e4.dealersearch.markers.math.formatDistance( marker.distanceToAddress )
                    } ) );
                } );
                jQuery( '.js-e-dealersearch-list' ).removeClass( 'is-empty' ).html( e4.handlebars.tmpl.markerList( json ) );
            }
        },
        clear: function () {
            jQuery( '.js-e-dealersearch-list' ).addClass( 'is-empty' ).html( '' );
        }
    }
}
;

