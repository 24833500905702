e4.section = {
    init: function () {
        if ( e4.settings.event.transitionend !== false ) {
            e4.section.sticky.init();
        }
    },
    sticky: {
        init: function () {
            var jSection = jQuery( '.js-e-section.is-sticky' );
            if ( jSection.length > 0 ) {
                jSection.data( 'isTransitioning', false );
                e4.section.sticky.isRAF = false;
                e4.section.sticky.updateOffset();
                e4.section.sticky.bindEvents();
                e4.section.sticky.toggle();
            }
        },
        bindEvents: function () {
            jQuery( window ).on( 'scroll', function () {
                if ( e4.section.sticky.isRAF === false ) {
                    window.requestAnimationFrame( function () {
                        e4.section.sticky.toggle();
                        e4.section.sticky.isRAF = false;
                    } );
                    e4.section.sticky.isRAF = true;
                }
            } );
        },
        updateOffset: function () {
            jQuery( '.js-e-section.is-sticky' ).not( '.is-stuck' ).each( function () {
                var jThis = jQuery( this ),
                    intOffsetTop;
                jThis.removeClass( 'is-sticky' );
                intOffsetTop = parseInt( jThis.offset().top );
                jThis.data( {
                    'intOffsetStick': intOffsetTop + parseInt( jThis.css( 'padding-top' ) ),
                    'intOffsetUnStick': intOffsetTop
                });
                jThis.addClass( 'is-sticky' );
            } );
        },
        toggle: function () {
            var intSrollTop = jQuery( window ).scrollTop();
            jQuery( '.js-e-section.is-sticky' ).each( function () {
                var jThis = jQuery( this );
                if ( jThis.data( 'isTransitioning' ) === false ) {
                    if ( jThis.is( '.is-stuck' ) === false && intSrollTop > jThis.data( 'intOffsetStick' ) ) {
                        jThis.data( 'isTransitioning', true );
                        jThis.one( e4.settings.event.transitionend, function () {
                            e4.section.sticky.updateOffset();
                            jThis.data( 'isTransitioning', false );
                        } );
                        jThis.addClass( 'is-stuck' );
                    }
                    else if ( jThis.is( '.is-stuck' ) === true && intSrollTop <= jThis.data( 'intOffsetUnStick' ) ) {
                        jThis.data( 'isTransitioning', true );
                        jThis.one( e4.settings.event.transitionend, function () {
                            e4.section.sticky.updateOffset();
                            jThis.data( 'isTransitioning', false );
                        } );
                        jThis.removeClass( 'is-stuck' );
                    }
                }
            } );
        }
    }
};

