e4.product = {
    init: function () {
        if ( jQuery( '.js-e-product-form, .js-e-productlist' ).length > 0 ) {
            e4.product.bindEvents();
        }
    },
    bindEvents: function () {
        jQuery( document ).on( 'submit', '.js-e-product-form', function ( event ) {
            var jForm = jQuery( event.target );
            if ( jForm.is( '.js-e-product-form-removefromcart' ) === true ) {
                e4.product.removeFromCart( event );
            }
            else /* if ( jForm.is( '.js-e-product-form-addtocart' ) === true )*/ {
                e4.product.addToCart( event );
            }
        } );
    },
    addToCart: function ( event ) {
        event.preventDefault();
        var jForm       = jQuery( event.target ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.addProduct( objFormData );
        }
        else {
            e4.cart.addProduct( objFormData );
        }
    },
    removeFromCart: function ( event ) {
        event.preventDefault();
        var jForm       = jQuery( event.target ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.removeProduct( objFormData );
        }
        else {
            e4.cart.removeProduct( objFormData );
        }
    }
};
