//TODO: Lav inpage search form
e4.search = {
    init: function () {
        if ( jQuery( '.js-e-search' ).length > 0 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'loop' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-search-result-item-product #js-e-handlebars-tmpl-search-result-item-page' );
                e4.search.event = e4.util.registerEvents( 'getresult', 'search' );
                e4.search.bindEvents();
                e4.search.loadIndex();
                if ( e4.util.hash.get( 'search' ) !== undefined ) {
                    jQuery( '.js-e-search-modal' ).filter( ':not(.show)' ).modal( 'show' );
                }
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-search-link' ).on( 'click', function ( event ) {
            event.preventDefault();
            if ( e4.util.hash.get( 'search' ) === undefined ) {
                e4.util.hash.set( 'search' );
            }
        } );
        jQuery( '.js-e-search-modal' ).on( 'shown.bs.modal hide.bs.modal', function ( event ) {
            e4.search.handleModal( event.target, event );
        } );
        jQuery( '.js-e-search-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
            e4.search.result.get( jQuery( event.target ).find( '.js-e-search-input' ).get( 0 ) );
        } ).find( '.js-e-search-input' ).on( 'input', function ( event ) {
            e4.search.toggleInputGroupState( event.target );
            e4.util.debounce( function () {
                jQuery( event.target ).closest( '.js-e-search-form' ).triggerHandler( 'submit' );
            }, e4.settings.search.debounceDelay ).apply( this );
        } ).on( 'change', function ( event ) {
            if ( event.target.value !== '' && e4.util.hash.get( 'search' ) !== undefined ) {
                e4.util.hash.replace( 'search', event.target.value );
            }
        } );
        jQuery( '.js-e-search-clear' ).on( 'click', function () {
            e4.search.clearInput( this );
        } );
        jQuery( window ).on( 'hashchange', function () {
            var hashSearch = e4.util.hash.get( 'search' );
            if ( hashSearch === undefined ) {
                jQuery( '.js-e-search-modal' ).filter( '.show' ).modal( 'hide' );
            }
            else {
                jQuery( '.js-e-search-modal' ).filter( ':not(.show)' ).modal( 'show' );
            }
        } );
    },
    loadIndex: function () {
        if ( e4.settings.search.result.products.show === true ) {
            jQuery.get( e4.settings.search.result.products.url + ( new Date() ).getTime() );
        }
        if ( e4.settings.search.result.pages.show === true ) {
            jQuery.get( e4.settings.search.result.pages.url + ( new Date() ).getTime() );
        }
    },
    handleModal: function ( domElm, event ) {
        var jSearchInput = jQuery( domElm ).find( '.js-e-search-input' ),
            strQuery     = jSearchInput.val(),
            hashSearch   = e4.util.hash.get( 'search' );
        if ( event.type === 'shown' ) {
            if ( hashSearch !== undefined && hashSearch !== '' ) {
                jSearchInput.val( hashSearch ).triggerHandler( 'input' );
            }
            else if ( strQuery !== '' ) {
                e4.util.hash.set( 'search', strQuery );
                jSearchInput.triggerHandler( 'input' );
            }
            else {
                e4.util.hash.set( 'search' );
            }
            jSearchInput.trigger( 'focus' );
        }
        else {
            jSearchInput.trigger( 'blur' );
            if ( hashSearch !== undefined ) {
                e4.util.hash.remove( 'search' );
                //console.log( 'remove on hash modal hide' );
            }
        }
    },
    toggleInputGroupState: function ( domElm ) {
        var jSearchInputGroup = jQuery( domElm ).closest( '.js-e-search-input-group' );
        if ( domElm.value === '' ) {
            jSearchInputGroup.addClass( 'is-empty' );
        }
        else {
            jSearchInputGroup.removeClass( 'is-empty' );
        }
    },
    clearInput: function ( domElm ) {
        jQuery( domElm ).closest( '.js-e-search-input-group' ).find( '.js-e-search-input' ).val( '' ).trigger( 'focus' ).triggerHandler( 'input' );
    },
    result: {
        get: function ( domElm ) {
            var jSearch     = jQuery( domElm ).closest( '.js-e-search' ),
                arrPromises = [];
            jSearch.find( '.js-e-search-loading-overlay' ).addClass( 'is-loading' );
            if ( jQuery.trim( domElm.value ) !== '' ) {
                if ( e4.settings.search.result.products.show === true ) {
                    arrPromises.push( e4.search.result.products.get( domElm ) );
                }
                if ( e4.settings.search.result.pages.show === true ) {
                    arrPromises.push( e4.search.result.pages.get( domElm ) );
                }
            }
            else {
                if ( e4.settings.search.result.products.show === true ) {
                    arrPromises.push( e4.search.result.products.abort( domElm ) );
                }
                if ( e4.settings.search.result.pages.show === true ) {
                    arrPromises.push( e4.search.result.pages.abort( domElm ) );
                }
            }
            this.promise = jQuery.when.apply( jQuery, arrPromises ).then( function () {
                e4.search.result.count = e4.search.result.products.count + e4.search.result.pages.count;
                jQuery( domElm ).closest( '.js-e-search-form' ).trigger( e4.search.event.getresult, {
                    query: jQuery.trim( domElm.value ),
                    resultCount: e4.search.result.count
                } );
                e4.search.result.show( domElm );
            }, function () {
                e4.search.result.hide( domElm );
            } );
        },
        show: function ( domElm ) {
            var jSearch = jQuery( domElm ).closest( '.js-e-search' );
            jSearch.find( '.js-e-search-result-container' ).addClass( 'is-active' );
            jSearch.find( '.js-e-search-result-count' ).text( e4.search.result.count );
            jSearch.find( '.js-e-search-loading-overlay' ).removeClass( 'is-loading' );
        },
        hide: function ( domElm ) {
            var jSearch = jQuery( domElm ).closest( '.js-e-search' );
            jSearch.find( '.js-e-search-result-container' ).removeClass( 'is-active' );
            e4.search.result.products.clear( domElm );
            e4.search.result.pages.clear( domElm );
            jSearch.find( '.js-e-search-loading-overlay' ).removeClass( 'is-loading' );
        },
        products: {
            count: 0,
            get: function ( domElm ) {
                //console.log( this.jqXHR )
                if ( this.jqXHR !== undefined && this.jqXHR.readyState !== 4 ) {
                    this.jqXHR.abort();
                }
                this.jqXHR = jQuery.ajax( {
                    url: e4.settings.search.result.products.url + encodeURIComponent( jQuery.trim( domElm.value ) ),
                    method: 'GET',
                    dataType: 'json',
                    cache: false
                } );
                this.jqXHR.done( function ( data ) {
                    e4.search.result.products.update( domElm, data );
                } );
                return this.jqXHR;
            },
            abort: function () {
                if ( this.jqXHR !== undefined && this.jqXHR.readyState !== 4 ) {
                    return this.jqXHR.abort();
                }
                return jQuery.Deferred().reject();
            },
            update: function ( domElm, data ) {
                this.count = data.products.length;
                if ( this.count > 0 ) {
                    var jSearch       = jQuery( domElm ).closest( '.js-e-search' ),
                        intMaxResults = ( e4.settings.search.result.pages.show === true ) ? e4.settings.search.result.products.max[ e4.util.getBreakPoint() ] : 120,
                        dataSliced    = {
                            products: data.products.slice( 0, intMaxResults )
                        };
                    jSearch.find( '.js-e-search-result-products' ).html( e4.handlebars.tmpl.searchResultItemProduct( dataSliced ) ).removeClass( 'is-empty' );
                    e4.search.result.products.toggleShowAllLink( domElm, data, intMaxResults );
                }
                else {
                    e4.search.result.products.clear( domElm );
                }
            },
            clear: function ( domElm ) {
                jQuery( domElm ).closest( '.js-e-search' ).find( '.js-e-search-result-products' ).addClass( 'is-empty' ).html( '' );
            },
            toggleShowAllLink: function ( domElm, data, intMaxResults ) {
                var jShowAllLink = jQuery( domElm ).closest( '.js-e-search' ).find( '.js-e-search-products-show-all' );
                if ( e4.search.result.products.count > intMaxResults ) {
                    jShowAllLink.find( 'a' ).attr( 'href', e4.settings.search.result.products.showAllLink + domElm.value ).on( 'click', {
                        'domElm': domElm,
                        'products': data
                    }, e4.search.result.products.showAll );
                }
                else {
                    jShowAllLink.addClass( 'd-none' );
                    jShowAllLink.find( 'a' ).off( 'click', e4.search.result.products.showAll );
                }
            },
            showAll: function ( event ) {
                if ( e4.search.result.products.count > 0 && event.data !== undefined ) {
                    event.preventDefault();
                    var jSearch = jQuery( event.data.domElm ).closest( '.js-e-search' );
                    jSearch.find( '.js-e-search-result-products' ).html( e4.handlebars.tmpl.searchResultItemProduct( event.data.products ) ).removeClass( 'is-empty' );
                    jSearch.find( '.js-e-search-products-show-all' ).addClass( 'd-none' );
                }
            }
        },
        pages: {
            count: 0,
            get: function ( domElm ) {
                if ( this.jqXHR !== undefined && this.jqXHR.readyState !== 4 ) {
                    this.jqXHR.abort();
                }
                this.jqXHR = jQuery.ajax( {
                    url: e4.settings.search.result.pages.url + encodeURIComponent( jQuery.trim( domElm.value ) ),
                    method: 'GET',
                    dataType: 'json',
                    cache: false
                } );
                this.jqXHR.done( function ( data ) {
                    e4.search.result.pages.update( domElm, data );
                } );
                return this.jqXHR;
            },
            abort: function () {
                if ( this.jqXHR !== undefined && this.jqXHR.readyState !== 4 ) {
                    return this.jqXHR.abort();
                }
                return jQuery.Deferred().reject();
            },
            update: function ( domElm, data ) {
                this.count = data.pages.length;
                if ( this.count > 0 ) {
                    var jSearch = jQuery( domElm ).closest( '.js-e-search' );
                    jSearch.find( '.js-e-search-result-pages' ).html( e4.handlebars.tmpl.searchResultItemPage( data ) ).removeClass( 'is-empty' );
                }
                else {
                    e4.search.result.pages.clear( domElm );
                }
            },
            clear: function ( domElm ) {
                jQuery( domElm ).closest( '.js-e-search' ).find( '.js-e-search-result-pages' ).addClass( 'is-empty' ).html( '' );
            }
        }
    }
};
