e4.gtm = {
    init: function () {
        if ( window.google_tag_manager !== undefined ) {
            e4.gtm.bindEvents();
        }
    },
    bindEvents: function () {
        jQuery( document )
        .on( 'userauthenticated.e4.login', e4.gtm.login )
        .on( 'productadded.e4.cart', e4.gtm.ecom.addProduct )
        .on( 'productremoved.e4.cart', e4.gtm.ecom.removeProduct );
        jQuery( '.js-e-search-form' )
        .on( 'getresult.e4.search', e4.gtm.search );
    },
    pushDataLayer: function ( objDataLayer ) {
        // console.log( objDataLayer )
        if ( window.dataLayer !== undefined ) {
            dataLayer.push( objDataLayer );
        }
    },
    login: function () {
        e4.gtm.pushDataLayer( {
            'LoggedOn': 'OK',
            'event': 'LoggedOnDone'
        } );
    },
    search: function ( event, data ) {
        e4.gtm.pushDataLayer( {
            'event': 'VirtualPageviewSearch',
            'virtualPageSearchURL': '/search?q=' + data.query,
            'virtualPageSearchTitle': 'Search'
        } );
        if ( data.resultCount === 0 ) {
            e4.gtm.pushDataLayer( {
                'eventCategory': 'Nulsøgning',
                'eventAction': data.query,
                'eventLabel': '{{Page URL}}',
                'eventValue': 0,
                'eventInteraction': true,
                'event': 'track-ga-event'
            } );
        }
    },
    ecom: {
        // https://developers.google.com/tag-manager/enhanced-ecommerce#cart
        addProduct: function ( event, data ) {
            // console.log( 'GTM: Add product', data );
            e4.gtm.pushDataLayer( {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: data.CurrencyCode,
                    add: {
                        products: [ {
                            name: data.Name,
                            id: data.Number,
                            price: data.Price,
                            quantity: data.Quantity
                        } ]
                    }
                }
            } );
        },
        removeProduct: function ( event, data ) {
            // console.log( 'GTM: Remove product', data );
            e4.gtm.pushDataLayer( {
                'event': 'removeFromCart',
                'ecommerce': {
                    currencyCode: data.CurrencyCode,
                    'remove': {
                        'products': [ {
                            name: data.Name,
                            id: data.Number,
                            price: data.Price,
                            quantity: data.Quantity
                        } ]
                    }
                }
            } );
        }
    }
};
