e4.handlebars = {
    tmpl: {},
    registerTemplates: function ( strTemplates ) {
        var arrTemplates = strTemplates.split( ' ' );
        for ( var i = 0; i < arrTemplates.length; i++ ) {
            if ( jQuery( arrTemplates[ i ] ).length === 1 ) {
                var tmplName = arrTemplates[ i ].replace( '#js-e-handlebars-tmpl-', '' ).replace( /-([a-z])/g, function ( strMatch ) {
                    return strMatch[ 1 ].toUpperCase();
                } );
                e4.handlebars.tmpl[ tmplName ] = Handlebars.compile( jQuery( arrTemplates[ i ] ).html() );
            }
        }
    },
    partial: {},
    registerPartials: function ( strPartials ) {
        var arrPartials = strPartials.split( ' ' );
        for ( var i = 0; i < arrPartials.length; i++ ) {
            if ( jQuery( arrPartials[ i ] ).length === 1 ) {
                var partialName = arrPartials[ i ].replace( '#js-e-handlebars-partial-', '' ).replace( /-([a-z])/g, function ( strMatch ) {
                    return strMatch[ 1 ].toUpperCase();
                } );
                Handlebars.registerPartial( partialName, jQuery( arrPartials[ i ] ).html() );
                e4.handlebars.partial[ partialName ] = Handlebars.compile( jQuery( arrPartials[ i ] ).html() );
            }
        }
    },
    registerHelpers: function ( strHelpers ) {
        var arrHelpers = strHelpers.split( ' ' );
        for ( var i = 0; i < arrHelpers.length; i++ ) {
            if ( window.Handlebars.helpers[ arrHelpers[ i ] ] === undefined ) {
                e4.handlebars.helpers[ arrHelpers[ i ] ]();
            }
        }
    },
    helpers: {
        compare: function () {
            Handlebars.registerHelper( 'compare', function ( v1, operator, v2, options ) {
                switch ( operator ) {
                case '===':
                    return ( v1 === v2 ) ? options.fn( this ) : options.inverse( this );
                case '!==':
                    return ( v1 !== v2 ) ? options.fn( this ) : options.inverse( this );
                case '<':
                    return ( v1 < v2 ) ? options.fn( this ) : options.inverse( this );
                case '<=':
                    return ( v1 <= v2 ) ? options.fn( this ) : options.inverse( this );
                case '>':
                    return ( v1 > v2 ) ? options.fn( this ) : options.inverse( this );
                case '>=':
                    return ( v1 >= v2 ) ? options.fn( this ) : options.inverse( this );
                case '&&':
                    return ( v1 && v2 ) ? options.fn( this ) : options.inverse( this );
                case '||':
                    return ( v1 || v2 ) ? options.fn( this ) : options.inverse( this );
                case '!!':
                    return ( !v1 && !v2 ) ? options.fn( this ) : options.inverse( this );
                case '!|':
                    return ( !v1 && v2 ) ? options.fn( this ) : options.inverse( this );
                case '|!':
                    return ( v1 && !v2 ) ? options.fn( this ) : options.inverse( this );
                default:
                    return options.inverse( this );
                }
            } );
        },
        counter: function () {
            Handlebars.registerHelper( 'counter', function ( index ) {
                return index + 1;
            } );
        },
        loop: function () {
            Handlebars.registerHelper( 'loop', function ( items, options ) {
                var out = '';
                for ( var i = 0, l = items.length; i < l; i++ ) {
                    out = out + options.fn( items[ i ] );
                }
                return out;
            } );
        }
    }
};

