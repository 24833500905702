e4.util = {
    init: function () {
        e4.util.catchInternalBackLinks();
        e4.util.catchBlockLinks();
    },
    isInViewport: function ( jElement ) {
        var domElm = jElement.get( 0 ),
            bounds = domElm.getBoundingClientRect();
        return bounds.top < window.innerHeight && bounds.bottom > 0;
    },
    require: {
        ajax: function ( strURL ) {
            if ( strURL !== undefined && strURL !== null && strURL !== '' ) {
                return jQuery.ajax( {
                    url: strURL,
                    cache: true,
                    timeout: e4.settings.require.timeout,
                    dataType: 'script'
                } );
            }
            else {
                return jQuery.Deferred().reject();
            }
        },
        js: function ( objScript, fnCallback, arrCallbackArgs ) {
            var strScriptName = objScript.url.split( '/' ).reverse()[ 0 ];
            // console.log( 'LOADING:', strScriptName );
            if ( objScript.promise !== undefined ) {// console.log( 'state', script.promise.state() );
            }
            if ( objScript.promise === undefined || objScript.promise.state() === 'rejected' ) {
                objScript.promise = jQuery.when(
                        e4.util.require.ajax( objScript.url )
                ).then( function () {
                    //TODO: remember to replace this function with null to resolve/reject chain then's with the
                    // same values as the original console.log( 'Success - FIRST', strScriptName );
                }, function () {
                    // console.log( 'Failure - FIRST', strScriptName );
                    return e4.util.require.ajax( objScript.fallback );
                } ).then( function () {
                    // console.log( 'Success - SECOND', strScriptName );
                    if ( typeof objScript.callback === 'function' ) {
                        objScript.callback.apply( objScript );
                        // console.log( 'callback for GLOBAL', strScriptName );
                    }
                }, function () {
                    //TODO: remember to replace this function with null to resolve/reject chain then's with the
                    // same values as the original console.log( 'Failure - SECOND', strScriptName ); console.warn(
                    // 'Failed to load script: ', strScriptName );
                } );
            }
            if ( typeof fnCallback === 'function' ) {
                objScript.promise.done( function () {
                    // console.log( 'callback for LOCAL', strScriptName );
                    fnCallback.apply( window, arrCallbackArgs );
                } );
            }
            return objScript.promise;
        }
    },
    registerEvents: function ( arrEvents, strNamespaceLocal ) {
        var objEvents    = {},
            strNamespace = [ e4.settings.event.namespace, strNamespaceLocal ].join( '.' );
        jQuery.each( arrEvents.split( ' ' ), function ( index, element ) {
            objEvents[ element ] = [ element, strNamespace ].join( '.' );
        } );
        return objEvents;
    },
    debounce: function ( fnFunction, intDelay ) {
        var intDeferTimer = null;
        return function () {
            var thisArg = this,
                arrArgs = arguments;
            clearTimeout( intDeferTimer );
            intDeferTimer = setTimeout( function () {
                fnFunction.apply( thisArg, arrArgs );
            }, intDelay );
        };
    },
    catchInternalBackLinks: function () {
        jQuery( document ).on( 'click', '.js-e-back-link', function ( event ) {
            if ( document.referrer.indexOf( location.protocol + '//' + location.host ) === 0 && history.length !== 1 ) {
                event.preventDefault();
                history.back();
            }
        } );
    },
    catchBlockLinks: function () {
        jQuery( document ).on( 'click', '.js-e-block-link', function ( event ) {
            var jBlockLink = jQuery( this ).find( 'a[href]' ).first();
            if ( jBlockLink.length === 1 && jQuery( event.target ).closest( 'a[href], form' ).length === 0 ) {
                if ( jBlockLink.attr( 'target' ) === '_blank' ) {
                    window.open( jBlockLink.attr( 'href' ) );
                }
                else {
                    location.href = jBlockLink.attr( 'href' );
                }
            }
        } );
    },
    getBreakPoint: function () {
        var intWindowWidth = Math.max( jQuery( window ).width(), window.innerWidth );
        if ( intWindowWidth >= e4.settings.breakpoint.xs && intWindowWidth < e4.settings.breakpoint.sm ) {
            return 'xs';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.sm && intWindowWidth < e4.settings.breakpoint.md ) {
            return 'sm';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.md && intWindowWidth < e4.settings.breakpoint.lg ) {
            return 'md';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.lg && intWindowWidth < e4.settings.breakpoint.xl ) {
            return 'lg';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.xl ) {
            return 'xl';
        }
        return null;
    },
    hash: {
        get: function ( key ) {
            var returnValue,
                arrHashes = [];
            if ( location.hash.indexOf( '#/' ) === 0 && location.hash.lastIndexOf( '/' ) === location.hash.length - 1 ) {
                arrHashes = location.hash.substring( 2, location.hash.length - 1 ).split( '&' );
            }
            if ( key === undefined ) {
                returnValue = {};
            }
            else {
                key = e4.util.hash.decode.key( e4.util.hash.encode.key( key ) );
            }
            for ( var i = 0; i < arrHashes.length; i++ ) {
                try {
                    var arrHashKeyValue = arrHashes[ i ].split( '=' ),
                        hashKey         = e4.util.hash.decode.key( arrHashKeyValue[ 0 ] ),
                        hashValue       = e4.util.hash.decode.value( arrHashKeyValue.slice( 1 ).join( '=' ) );
                    if ( key === hashKey ) {
                        returnValue = hashValue;
                        break;
                    }
                    else if ( key === undefined ) {
                        returnValue[ hashKey ] = hashValue;
                    }
                }
                catch ( e ) {
                }
            }
            return returnValue;
        },
        set: function ( key, value ) {
            var arrHashes = e4.util.hash.buildNewHashArray( key, value ),
                strHash   = e4.util.hash.buildNewHashString( arrHashes );
            //console.log( location.hash, e4.util.hash.getCurrentHashStringValue(), strHash, arrHashes );
            if ( e4.util.hash.getCurrentHashStringValue() !== strHash ) {
                location.hash = strHash;
                //console.log( 'hash changed - set' );
            }
        },
        replace: function ( key, value ) {
            var arrHashes      = e4.util.hash.buildNewHashArray( key, value ),
                strHashNew     = e4.util.hash.buildNewHashString( arrHashes ),
                strHashCurrent = e4.util.hash.getCurrentHashStringValue(),
                strURL         = location.href;
            //console.log( location.hash, e4.util.hash.getCurrentHashStringValue(), strHashNew, arrHashes );
            if ( strHashCurrent !== strHashNew ) {
                if ( location.href.indexOf( '#' ) === -1 ) {
                    strURL = strURL + '#' + strHashNew;
                }
                else {
                    strURL = strURL.replace( '#' + strHashCurrent, '#' + strHashNew );
                }
                window.history.replaceState( null, '', strURL );
                jQuery( window ).triggerHandler( 'hashchange' );
                //console.log( 'hash changed - replace' );
            }
        },
        remove: function ( key ) {
            e4.util.hash.set( key, null );
        },
        buildNewHashArray: function ( key, value ) {
            var arrHashes     = [],
                hashKeyExists = false;
            key = e4.util.hash.encode.key( key );
            jQuery.each( e4.util.hash.get(), function ( hashKey, hashValue ) {
                hashKey = e4.util.hash.encode.key( hashKey );
                hashValue = e4.util.hash.encode.value( hashValue );
                if ( hashKey === key ) {
                    hashKeyExists = true;
                    if ( value !== null ) {
                        hashValue = e4.util.hash.encode.value( value );
                        arrHashes.push( hashKey + '=' + hashValue );
                    }
                }
                else {
                    arrHashes.push( hashKey + '=' + hashValue );
                }
            } );
            if ( hashKeyExists === false && value !== null ) {
                arrHashes.push( key + '=' + e4.util.hash.encode.value( value ) );
            }
            return arrHashes;
        },
        buildNewHashString: function ( arrHashes ) {
            var strHash = '';
            if ( arrHashes.length !== 0 ) {
                strHash = '/' + arrHashes.join( '&' ) + '/';
            }
            return strHash;
        },
        getCurrentHashStringValue: function () {
            return location.hash.replace( /^#/, '' );
        },
        encode: {
            key: function ( key ) {
                return encodeURIComponent( String( key ).replace( /\s/g, '_' ) );
            },
            value: function ( value ) {
                if ( String( value ).charAt( 0 ) === '{' || typeof value === 'object' ) {
                    try {
                        value = JSON.stringify( value );
                    }
                    catch ( e ) {
                    }
                }
                return value === undefined ? '' : encodeURIComponent( value );
            }
        },
        decode: {
            key: function ( key ) {
                return decodeURIComponent( key );
            },
            value: function ( value ) {
                value = decodeURIComponent( value );
                if ( value.charAt( 0 ) === '{' ) {
                    try {
                        value = JSON.parse( value );
                    }
                    catch ( e ) {
                    }
                }
                return value;
            }
        }
    }
};
